<script>
import { mapActions, mapState } from 'vuex'
import config from '@/config'

const { DEFAULT_ACCESSIBILITY } = config

export default {
  name: 'Actionbar',
  components: {
    Action: () => import('@/components/general/Action'),
    Dropdown: () => import('@/components/general/Dropdown'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    Icon: () => import('@/components/general/Icon'),
    Profile: () => import('@/components/general/Profile'),
    SearchBar: () => import('@/components/general/SearchBar')
  },
  props: {
    profile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultAccessibility: DEFAULT_ACCESSIBILITY,
      increaseFontDisabled: false,
      decreaseFontDisabled: true
    }
  },
  watch: {
    accessibilityFontSize: function (value) {
      this.increaseFontDisabled = value === 2
      this.decreaseFontDisabled = value === 0
    }
  },
  computed: {
    ...mapState(['accessibilityFontSize']),
    sessionUuid () {
      return this.$route.params.session_uuid
    }
  },
  methods: {
    ...mapActions([
      'setAccessibility',
      'setFontsize'
    ]),
    highContrast () {
      this.setAccessibility()
    },
    increaseFont () {
      this.accessibilityFontSize < 2 && this.setFontsize(this.accessibilityFontSize + 1)
    },
    decreaseFont () {
      this.accessibilityFontSize > 0 && this.setFontsize(this.accessibilityFontSize - 1)
    },
    changeLanguage (language) {
      this.$i18n.locale = language
    }
  }
}
</script>

<template>
  <div class="action-bar">
    <!-- <div class="action-bar-item" v-if="!profile"> -->
    <div
      v-if="false"
      class="action-bar-item"
    >
      <search-bar
        :classes="'action-bar-link'"
        :label="$t('global:actionbar.search.label')"
      />
    </div>
    <!-- <div class="action-bar-item" v-if="!sessionUuid && !profile">
      <action :url="{name: 'basket.index'}" class="action-bar-link" icon="shopping_basket"></action>
    </div>
    <div class="action-bar-item" v-if="!profile">
      <action type="link" class="action-bar-link" icon="notifications"></action>
    </div> -->
    <!-- <div class="action-bar-item" v-if="!profile">
      <dropdown :classes="'action-bar-link'" icon="accessibility" right>
        <dropdown-link icon="compare" :text="$t('global:accessibility.high.contrast')" @click="highContrast()" v-if="false"></dropdown-link>
        <dropdown-link icon="format_size" :text="$t('global:accessibility.increase.font')" @click="increaseFont()" :disabled="increaseFontDisabled"></dropdown-link>
        <dropdown-link icon="text_fields" :text="$t('global:accessibility.decrease.font')" @click="decreaseFont()" :disabled="decreaseFontDisabled"></dropdown-link>
      </dropdown>
    </div> -->
    <div class="action-bar-item">
      <!--      <profile></profile>-->
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/actionbar.css"></style>
